import { useStaticQuery, graphql } from "gatsby"

const ALL_DISCOUNT_COUPONS = graphql`
  {
    allAirtableallViatrisCoupons(
      filter: { data: { ShowOnWebsite: { eq: true } } }
    ) {
      nodes {
        data {
          activation: ActivationDate
          code: Code
          percentOff: PercentOff
          productInclusions: ProductInclusions
          productExclusions: ProductExclusions
          company: CompanyNames
          websiteDescription: WebsiteDescription
        }
      }
    }
  }
`

export const useDiscountCoupons = () => {
  const QUERY = useStaticQuery(ALL_DISCOUNT_COUPONS)
  let coupons = QUERY.allAirtableallViatrisCoupons?.nodes
  coupons = coupons?.map((coupon) => ({
    ...coupon?.data,
    productExclusions: JSON.parse(coupon?.data?.productExclusions),
    productInclusions: JSON.parse(coupon?.data?.productInclusions),
  }))

  return coupons
}
