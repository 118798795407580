import React from "react"
import Upload from "../../components/Upload/Upload"
import Layout from "../../components/Layout/Layout"

export default ({ location }) => {
  return (
    <Layout
      title="Upload Prescription"
      process="epharmacy"
      seoTitle="Upload Prescription"
      display={{ footer: false, helpCenterBanner: false }}
      path={location?.pathname}
    >
      <Upload
        flow="epharmacy"
        backRoute="/epharmacy/mechanics"
        nextRoute="/epharmacy/order"
      />
    </Layout>
  )
}
