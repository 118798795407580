import React, { useState, useEffect, useContext } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Dropzone from "./Dropzone"
import Brand from "../Elements/Brand"
import Section from "../Elements/Section"
import CartLayout from "../Layout/CartLayout"
import EpharmacyCart from "../Epharmacy/Cart"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"
import UploadGuidelines from "./UploadGuidelines"

import { AppContext } from "../../context/AppContext"
import { isObjectEmpty } from "../../services/general"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getContextFromSession } from "context/services/context"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { useDiscountCoupons } from "../Epharmacy/hooks/useDiscountCoupons"
import { beneficiaryValidationSchema } from "components/Epharmacy/utils/formData.js"
import beneficiaries from "../../components/PersonalDetails/utils/beneficiaryTypes.json"
import {
  zendeskUploadFiles,
  generateUploadFiles,
} from "../../services/zendeskService"
import FormDate from "../Elements/Form/FormDate"
import FormInputSourced from "../Elements/Form/FormInputSourced"

const MAX_FILE_SIZE_IN_BYTES = 4000000
const MAX_FILE_COUNT = 10

const Upload = ({ backRoute, nextRoute, flow }) => {
  const { dispatch, state } = useContext(AppContext)
  const [filesUploaded, setFilesUploaded] = useState(state.documents)
  const [isOthers, setIsOthers] = useState(false)
  const { epharmacy } = state
  let formValues = getContextFromSession()
  if (isObjectEmpty(formValues)) formValues = state

  const Cart = (
    <EpharmacyCart
      open={{ documentsCard: true }}
      hidden={{ documents: false, medicines: true }}
    />
  )
  let coupons = useDiscountCoupons()
  let noDocuments =
    state.documents.filter((file) => file.type === "RX").length === 0

  let backEmployeeId = state.documents.filter(
    (file) => file.type === "EMPLOYEE_BACK"
  )?.[0]

  let frontEmployeeId = state.documents.filter(
    (file) => file.type === "EMPLOYEE_FRONT"
  )?.[0]

  const noEmployeeID =
    !backEmployeeId || !frontEmployeeId || !epharmacy?.employeeIDNumber

  const beneficiaryInitialValues = {
    beneficiaryName: "",
    beneficiaryType: { relationship: "", type: "" },
  }

  const handleOnChange = (values) => {
    if (values.value === "others") {
      setIsOthers(true)
    } else setIsOthers(false)
  }

  const handleSubmit = async (values) => {
    let coupon = coupons.find((coupon) =>
      coupon.code.includes(values.beneficiaryType.type)
    )
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          ...values,
          coupon,
        },
      },
    })
    navigate(nextRoute)
  }

  // useEffect(() => {
  //   if (state.documents.length < filesUploaded.length)
  //     setFilesUploaded(state.documents)
  //   let documentsWithNoToken =
  //     state?.documents?.filter?.((document) => !document?.token)?.length || 0
  //   if (!state?.activeDocument && documentsWithNoToken > 0) {
  //     let currentDocumentWithNoToken = state?.documents?.find?.(
  //       (document) => !document?.token
  //     )
  //     dispatch({
  //       type: "SAVE_ACTIVE_DOCUMENT",
  //       payload: currentDocumentWithNoToken,
  //     })
  //     uploadToZendesk({ currentDocument: currentDocumentWithNoToken })
  //   }
  // }, [state])

  const uploadToZendesk = async ({ currentDocument }) => {
    const UPLOADED_FILES = await zendeskUploadFiles([currentDocument], null)
    const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)

    let newTempDocument = {
      ...currentDocument,
      token: GENERATED_FILE_TOKEN?.[0],
    }
    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT",
      payload: {
        ...newTempDocument,
      },
    })
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    if (state?.epharmacy.beneficiaryType.value === "others") {
      setIsOthers(true)
    }
  }, [])

  return (
    <Formik
      initialValues={formValues.epharmacy}
      onSubmit={handleSubmit}
      validationSchema={beneficiaryValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form autoComplete="off">
          <CartLayout
            isCentered
            desktop={8}
            fullhd={8}
            submit={{
              label: "Next",
              disabled: noDocuments || noEmployeeID,
            }}
            back={{
              label: "Back",
              link: backRoute,
              // link: "/",
            }}
            cartContainer={Cart}
          >
            <div className="mb-3">
              <Section title="Upload Prescription">
                <Dropzone
                  maxByteSize={MAX_FILE_SIZE_IN_BYTES}
                  maxFileCount={MAX_FILE_COUNT}
                  docType="RX"
                  flow="uploads"
                />
                {noDocuments && (
                  <p className="help has-text-danger">
                    Please upload your prescription.
                  </p>
                )}
                <div className="message is-light is-size-6 mt-3">
                  <div className="message-body">
                    <span className="icon has-text-primary">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                    Your request will be validated based on the name on the
                    prescription. Only registered employees and dependents as
                    provided by <Brand /> will be eligible for this benefit.
                  </div>
                </div>
              </Section>
            </div>

            <div className="mt-3">
              <Section title="Input Prescription Details">
                <FormInput
                  name="beneficiaryFirstName"
                  placeholder="Juana"
                  value={values.beneficiaryFirstName}
                  maxLength={50}
                  label="First Name in Prescription"
                  isRequired
                />
                <FormInput
                  name="beneficiaryLastName"
                  placeholder="Dela Cruz"
                  value={values.beneficiaryLastName}
                  maxLength={50}
                  label="Last Name in Prescription"
                  isRequired
                />
                <FormSelect
                  name="beneficiaryType"
                  placeholder="Self"
                  value={values.beneficiaryType}
                  label="Relationship to Employee"
                  helper="Beneficiary is your:"
                  options={beneficiaries}
                  onChange={handleOnChange}
                  isRequired
                />
                {isOthers && (
                  <FormInput
                    name="beneficiaryOthers"
                    placeholder="Cousin"
                    value={values.beneficiaryOthers}
                    label="Please Specify Relationship to Employee"
                    isRequired={isOthers}
                  />
                )}
                <FormInput
                  name="prescribingHospital"
                  label="Prescribing Hospital"
                  placeholder="MedGrocer"
                  isRequired
                />
                <FormInput
                  name="prescribingDoctor"
                  label="Doctor's Name"
                  placeholder="Maria Dela Cruz"
                  isRequired
                />
                <FormInput
                  name="prcNumber"
                  label="Doctor's PRC Number"
                  maxLength={10}
                  placeholder="1234567"
                  isRequired
                />
                <FormDate
                  isRequired
                  name="prescriptionDate"
                  label="Consultation Date"
                  setFieldValue={setFieldValue}
                  values={values?.prescriptionDate}
                  fieldNames={["month", "date", "year"]}
                  config={{ yearsBack: 2, past: true }}
                />
                <FormInputSourced
                  name="prescriptionPassword"
                  label="File Password"
                  value={values.prescriptionPassword || ""}
                  type="password"
                  helper="If necessary, please provide the password to the file."
                />
              </Section>
            </div>
          </CartLayout>
        </Form>
      )}
    </Formik>
  )
}

export default Upload
